body .header .header-main .actions-main-header .header-search__button_filters {
  border: 1px solid #B5BACB;
}
body .header .header-main .actions-main-header .actions-main-header__search {
  border: 1px solid #B5BACB;
}
body .header .header-main .actions-main-header__search::before {
  opacity: 0;
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .actions-main-header .header-search__button_filters {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .header .header-main .actions-main-header .actions-main-header__search {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .header .header-main .actions-main-header__search::before {
    opacity: 1;
  }
}

html.dark body .header .header-main .actions-main-header .header-search__button_filters {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .header .header-main .actions-main-header .actions-main-header__search {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .header .header-main .actions-main-header__search::before {
    opacity: 1;
}
html.light body .header .header-main .actions-main-header .actions-main-header__search {
  border: 1px solid #B5BACB;
}
html.light body .header .header-main .actions-main-header__search::before {
  opacity: 0;
}

.search-modal {
  display: none;
  position: absolute;
  top: -150vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
  z-index: 950;
  transition: transform .3s ease-out;
  will-change: transform;
}

.search-modal.active {
  transform: translateY(150vh);
}

.search-icon {
  display: none;
  z-index: 100;
}

@media (max-width: 1210px) {
  .search-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 1rem;
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  body.scroll-none .search-icon {
    color: #323232
  }
}
.actions-main-header__search {
    height: 100%;
    width: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    -webkit-transition: border ease-in-out 0.3s;
    transition: border ease-in-out 0.3s;
  }
  
  @media (max-width: 767.98px) {
    .actions-main-header__search {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      position: relative;
    }
  }
  
  @media (max-width: 480px) {
    .actions-main-header__search {
      width: 35px;
      height: 35px;
    }
  }

.header-search__button {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.header-search__button i {
  font-size: 1.5rem;
  color: #2A59FF;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

@media (max-width: 767.98px) {
  .header-search__button {
    width: 100%;
    height: 100%;
  }
  .header-search__button i {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .header-search__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.header-search__button:hover i {
  color: #1039cc;
}

.header-search__input {
  background-color: transparent;
  height: 100%;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  margin: 0 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  color: #515764;
}

@media (max-width: 767.98px) {
  .header-search__input {
    display: none;
  }
}

.header-search__button_filters {
  color: #2A59FF;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  -webkit-transition: border ease-in-out 0.3s, color .3s ease-out;
  transition: border ease-in-out 0.3s, color .3s ease-out;
  will-change: color, border;
}
.header-search__button_filters:hover,
.header-search__button_filters:active {
  color: #1039cc;
}

@media (max-width: 767.98px) {
  .header-search__button_filters {
    display: none;
  }
}

.search-div {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  transition: background .3s ease-out;
  will-change: background;
}
.search-div:hover,
.search-div:active {
  background: #e5e8f2;
}

.dark .search-div:hover,
.dark .search-div:active {
  background: #1e223b;
}

@media (max-width: 767.98px) {
    .search-div {
        width: 35px;
    }
}

.search-div .search-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-self: stretch;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background .3s ease-out, border .3s ease-out;
  will-change: background, border;
}

.search-div .search-row .search-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: .9rem;
}

.search-div .search-row .search-title svg {
  width: 1.05rem;
  height: 1.05rem;
  margin-right: .25rem;
}

.search-div .search-row .search-title p {
  margin: 0;
  line-height: 1;
  position: relative;
  top: 1px
}

.search-div .hotkey-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.5rem;
  background: #bdbdbd;
  border-radius: 0.25rem;
  color: #454545;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.05rem;
  font-size: .85rem;
}
.dark .search-div .hotkey-style {
    background: #1d2241;
    color: #ccc;
}

.search-div .search-area {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: -1px;
  right: -1px;
  background: #fff;
  border-radius: 0.5rem;
  width: 400px;
  min-height: 140px;
  max-height: 610px;
  box-shadow: 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
  z-index: 9999;
}
.dark .search-div .search-area {
    background: #1c2039;
    box-shadow: 0px 1px 2px rgba(28, 38, 57, 0.12), 0px 8px 32px rgba(28, 38, 57, 0.24);
}

.search-div .search-area.active {
  display: flex;
}

.search-div .search-area .input-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 1.25rem;
  color: #bdbdbd;
}
.dark .search-div .search-area .input-area {
  color: #fff;
}

.search-div .search-area .input-area .icon-search {
  margin-right: 0.5rem;
}

.dark .search-div .search-area .input-area .icon-search {
   color: #6d74a1
}

.search-div .search-area .input-area input {
  flex-grow: 1;
  font-size: .9rem;
  line-height: 1;
  margin-right: 0.5rem;
  outline: none;
}

.dark .search-div .search-area .input-area input {
  color: #fff;
  background: #1c2039;
}


.search-div .search-area .input-area input::placeholder {
  color: #e0e0e0;
}

.dark .search-div .search-area .input-area input::placeholder {
  color: #bdbdbd;
}

.search-div .search-area .input-area .icon-close {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color .3s ease-out;
  will-change: color;
}
.dark .search-div .search-area .input-area .icon-close {
  color: #6d74a1;
}

.search-div .search-area .input-area .icon-close:hover,
.search-div .search-area .input-area .icon-close:active {
  color: #9e9e9e
}

.dark .search-div .search-area .input-area .icon-close:hover,
.dark .search-div .search-area .input-area .icon-close:active {
  color: #bdbdbd
}

.search-div .search-area .search-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 0 .5rem;
  max-height: 530px;
  overflow: auto;
}

.search-div .search-area .search-list::-webkit-scrollbar-track {
  border-radius: 5rem;
  background-color: #f5f5f5;
}

.search-div .search-area .search-list::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #fff;
}

.search-div .search-area .search-list::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: #cfd8dc;
}

.dark .search-div .search-area .search-list::-webkit-scrollbar-track {
  background-color: #6d74a1;
}

.dark .search-div .search-area .search-list::-webkit-scrollbar {
  background-color: #1c2039;
}

.dark .search-div .search-area .search-list::-webkit-scrollbar-thumb {
  background-color: #474c71;
}

.search-div .search-area .search-list .list {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.search-div .search-area .search-list .list.empty-list {
  margin: 2rem 0;
}

.search-div .search-area .search-list .list.active {
  display: flex;
}

.search-div .search-area .search-list .list .list-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.search-div .search-area .search-list .list .list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: .5rem;
  padding: 0.5rem 0.75rem;
  transition: background .3s ease-out;
  will-change: background;
}

.search-div .search-area .search-list .list .list-item:hover,
.search-div .search-area .search-list .list .list-item:active {
  cursor: pointer;
  background: #fafafa;
}
.dark .search-div .search-area .search-list .list .list-item:hover,
.dark .search-div .search-area .search-list .list .list-item:active {
  background: #242844
}

.search-div .search-area .search-list .list .list-item .text-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.search-div .search-area .search-list .list .list-item .text-data .image {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: .5rem;
  border-radius: 50%;
  overflow: hidden;
}

.search-div .search-area .search-list .list .list-item .text-data .image img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  overflow: hidden;
}

.search-div .search-area .search-list .list .list-item .text-data .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.search-div .search-area .search-list .list .list-item .text-data .title p {
  color: #323232;
  font-size: .85rem;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
.dark .search-div .search-area .search-list .list .list-item .text-data .title p {
  color: #fff
}

.search-div .search-area .search-list .list .list-item .text-data .title p.symbol {
  margin-top: .25rem;
  color: #bdbdbd
}
.dark .search-div .search-area .search-list .list .list-item .text-data .title p.symbol {
  color: #6d74a1
}

.search-div .search-area .search-list .list .list-item .rating {
  font-size: .85rem;
  font-weight: 500;
  color: #bdbdbd
}
.dark .search-div .search-area .search-list .list .list-item .rating {
  color: #6d74a1
}

.search-div .search-area .search-list .list:not(:last-child) {
  margin-bottom: 1rem;
}

.search-div .search-area .search-list .list .header-desc {
  font-size: .75rem;
  padding: 0 0.75rem;
  margin-bottom: .5rem;
  color: #9e9e9e
}
.dark .search-div .search-area .search-list .list .header-desc {
  color: #6d74a1
}

.search-div .search-area .search-list .list .search-icon-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0 1rem;
}

.search-div .search-area .search-list .list .search-icon-area .icon-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f5f5f5;
  color: #9e9e9e;
  padding: 0.75rem;
  font-size: 1.15rem;
  width: 3rem;
  height: 3rem;
}
.dark .search-div .search-area .search-list .list .search-icon-area .icon-circle {
  background: #3b426e;
  color: #bdbdbd;
}

.search-div .search-area .search-list .list .h5 {
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.search-div .search-area .search-list .list .h6 {
  font-size: .85rem;
  font-weight: 400;
  color: #9e9e9e;
  line-height: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
}
.dark .search-div .search-area .search-list .list .h6 {
  color: #6d74a1;
}

.search-div .search-area .search-list .list .h6:not(:last-child) {
  margin-bottom: .25rem;
}

.search-div .search-area .search-list .list .searches-area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
}

.search-div .search-area .search-list .list .searches-area::-webkit-scrollbar-track {
  border-radius: 5rem;
  background-color: #f5f5f5;
}

.search-div .search-area .search-list .list .searches-area::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #fff;
}

.search-div .search-area .search-list .list .searches-area::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: #cfd8dc;
}

.search-div .search-area .search-list .list .searches-area .recent-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 6rem;
  height: 4.75rem;
  padding: 0.5rem 0.5rem;
  color: #323232;
  background: #f1f1f1;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background .3s ease-out;
  will-change: color;
}
.dark .search-div .search-area .search-list .list .searches-area .recent-search {
  color: #fff;
  background: #31375b
}

.search-div .search-area .search-list .list .searches-area .recent-search:hover,
.search-div .search-area .search-list .list .searches-area .recent-search:active {
  background: #f5f5f5;
}

.dark .search-div .search-area .search-list .list .searches-area .recent-search:hover,
.dark .search-div .search-area .search-list .list .searches-area .recent-search:active {
  background: #3d446c;
}

.search-div .search-area .search-list .list .searches-area .recent-search:not(:last-child) {
  margin-right: 1rem;
}

.search-div .search-area .search-list .list .searches-area .recent-search .image {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: .5rem;
  border-radius: 50%;
  overflow: hidden;
}

.search-div .search-area .search-list .list .searches-area .recent-search .image img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.search-div .search-area .search-list .list .searches-area .recent-search .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.search-div .search-area .search-list .list .searches-area .recent-search .title p {
  font-size: .85rem;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0.25rem;
  line-height: 1;
  text-align: center;
}

.search-div .search-area .search-list .list .searches-area .recent-search .title p.symbol {
  margin: 0;
  color: #9e9e9e;
}
.dark .search-div .search-area .search-list .list .searches-area .recent-search .title p.symbol {
  color: #6d74a1
}

.search-div .search-area .search-list .list .list-area .reveal-search {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0.75rem;
}

.search-div .search-area .search-list .list .list-area .reveal-search p {
  margin: 0;
  cursor: pointer;
  color: #2A59FF;
  font-size: .85rem;
}
.dark .search-div .search-area .search-list .list .list-area .reveal-search p {
  color: #2994FF;
}

.search-div .search-area .search-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1.25rem;
  background: #f5f5f5;
  border-radius: 0 0 0.5rem 0.5rem;
}
.dark .search-div .search-area .search-footer {
  background: #31375b
}

.search-div .search-area .search-footer .hint-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.search-div .search-area .search-footer .hint-area .hotkey-style {
  margin-right: 0.5rem;
  font-size: .75rem;
  padding: 0.25rem 0.35rem;
}

.search-div .search-area .search-footer .hint-area .title {
  font-size: .85rem;
}

@media (max-width: 1210px) {
  .search-div .search-area {
    top: 0;
    right: 0
  }
  .search-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }

  .search-div.active {
    position: absolute;
    top: -150vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 950;
    width: 100vw;
    transform: translateY(calc(150vh - 3rem)); /* for top panel margin */
    border: 0 !important;
    border-radius: 0;
    backdrop-filter: blur(2px);
    overflow: hidden;
    transition: transform .3s ease-out;
    will-change: transform;
  }

  .search-div .search-area .search-footer {
    display: none;
  }

  .search-div .search-area {
    box-shadow: none;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 2rem;
    background: rgb(248 250 253 / 95%);
  }
  .dark .search-div .search-area {
    background: rgb(45 45 45 / 95%);
  }

  .search-div .search-area .input-area {
    background: #fff;
    border-radius: .5rem;
    height: 40px;
  }
  .dark .search-div .search-area .input-area {
    background: #757575;
  }

  .search-div .search-area .input-area input {
    font-size: 1rem;
  }

  .search-div .search-area .search-list {
    margin-top: 2rem;
    max-height: calc(100% - 4rem - 40px);
  }

  .search-div .search-area .search-list .list .list-item {
    background: rgba(255, 255, 255, 1);
  }

  .dark .search-div .search-area .search-list .list .list-item {
    background: rgba(42, 42, 42, 1);
  }

  .search-div .search-area .search-list .list .list-item:not(:last-child) {
    margin-bottom: 1rem;
  }
  .search-div .search-area .search-list .list .header-desc {
    font-size: .9rem;
  }
}

@media (max-width: 822px) {
  .search-div .search-area {
    padding: 1rem;
  }

  .search-div .search-area .search-list {
    margin-top: 1rem;
    max-height: calc(100% - 2rem - 40px);
  }
}

.ajax-search {
  position: relative
}

.ajax-search ul {
  list-style-type: none;
  position: absolute;
  z-index: 10;
  padding: 0
}

.ajax-search ul.active {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 0
}

.ajax-search ul li {
  background-color: #f5f5f5;
  margin: 5px 10px;
  padding: 5px 10px
}

.ssearch_btn {
  height: 25px;
}
